import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M2205 3794 c-11 -3 -60 -14 -110 -26 -109 -25 -229 -79 -339 -152
-93 -62 -186 -155 -186 -186 0 -29 26 -76 45 -84 49 -18 88 -1 165 72 75 70
233 150 363 184 109 28 309 31 415 5 159 -38 278 -98 411 -207 84 -68 116 -76
156 -35 18 17 25 35 25 62 0 33 -7 45 -64 98 -74 70 -207 155 -304 195 -139
56 -224 73 -397 76 -88 1 -169 1 -180 -2z"/>
<path d="M2210 3464 c-132 -28 -280 -105 -369 -192 -49 -48 -56 -95 -18 -130
35 -33 77 -28 129 14 62 53 113 84 193 117 61 26 86 30 191 35 95 3 133 1 179
-13 81 -23 166 -68 242 -126 74 -57 110 -63 148 -24 36 35 32 68 -16 120 -152
165 -443 250 -679 199z"/>
<path d="M2235 3157 c-119 -40 -225 -123 -225 -176 0 -32 30 -68 65 -77 25 -6
34 -2 122 58 109 73 261 62 372 -27 24 -19 55 -35 68 -35 28 0 83 50 83 75 0
46 -58 101 -158 152 -68 34 -80 37 -180 40 -70 2 -121 -2 -147 -10z"/>
<path d="M2340 2833 c-24 -9 -61 -46 -71 -70 -10 -27 3 -69 33 -100 63 -67
180 15 151 103 -14 43 -77 80 -113 67z"/>
<path d="M1830 2500 c-289 -5 -307 -6 -346 -27 -23 -12 -49 -34 -58 -48 -22
-33 -23 -97 -2 -126 19 -27 19 -25 6 20 -12 44 7 90 51 122 32 22 45 24 187
28 l152 3 0 -121 0 -121 35 0 35 0 0 120 0 120 120 0 c115 0 122 -1 145 -25
l25 -24 0 44 c0 41 -2 45 -22 43 -13 -1 -160 -5 -328 -8z"/>
<path d="M2914 2480 c-115 -24 -226 -121 -255 -222 -8 -28 -7 -29 23 -26 27 2
33 9 40 37 31 117 141 195 275 193 90 -1 159 -33 204 -95 l32 -42 -7 45 c-4
27 -15 52 -27 62 -47 40 -202 66 -285 48z"/>
<path d="M2284 2390 c-30 -15 -48 -34 -68 -73 -33 -67 -34 -87 -1 -87 21 0 26
6 31 43 23 140 234 158 273 23 8 -27 9 -23 10 30 1 74 2 73 -119 80 -73 5 -91
2 -126 -16z"/>
<path d="M1485 2160 c3 -5 13 -10 21 -10 10 0 14 -13 14 -46 0 -27 4 -43 10
-39 6 3 10 24 10 46 0 28 4 39 15 39 8 0 15 5 15 10 0 6 -21 10 -46 10 -27 0
-43 -4 -39 -10z"/>
<path d="M1690 2115 c0 -30 5 -55 10 -55 6 0 10 11 10 25 0 20 5 25 25 25 20
0 25 -5 25 -25 0 -14 5 -25 10 -25 6 0 10 25 10 55 0 30 -4 55 -10 55 -5 0
-10 -9 -10 -20 0 -15 -7 -20 -25 -20 -18 0 -25 5 -25 20 0 11 -4 20 -10 20 -5
0 -10 -25 -10 -55z"/>
<path d="M1914 2156 c-3 -8 -4 -31 -2 -52 3 -36 6 -39 36 -42 18 -2 32 1 32 7
0 6 -11 11 -25 11 -16 0 -25 6 -25 15 0 9 10 15 26 15 14 0 23 4 19 10 -3 6
-15 10 -26 10 -10 0 -19 5 -19 10 0 6 11 10 25 10 14 0 25 5 25 10 0 15 -60
12 -66 -4z"/>
<path d="M2267 2164 c-15 -16 -6 -45 18 -56 36 -16 31 -31 -7 -22 -29 6 -31 5
-19 -10 16 -20 45 -21 60 -2 15 18 3 46 -20 46 -11 0 -19 7 -19 16 0 12 6 15
21 11 13 -3 19 -1 16 6 -4 14 -39 21 -50 11z"/>
<path d="M2460 2126 c0 -51 18 -71 54 -62 19 5 27 15 32 41 3 19 3 43 -2 52
-6 15 -8 14 -11 -7 -8 -59 -14 -70 -33 -70 -17 0 -20 7 -20 45 0 25 -4 45 -10
45 -5 0 -10 -20 -10 -44z"/>
<path d="M2680 2115 c0 -54 1 -55 29 -55 35 0 48 15 46 56 -2 43 -12 54 -46
54 -29 0 -29 -1 -29 -55z m50 25 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6
7 10 15 10 8 0 15 -4 15 -10z m8 -47 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16
0 18 31 15 38 -3z"/>
<path d="M3045 2147 c-31 -48 9 -103 60 -83 27 10 16 26 -15 20 -23 -5 -31 -2
-36 11 -11 26 10 50 41 48 35 -3 28 21 -8 25 -18 2 -30 -4 -42 -21z"/>
<path d="M3248 2119 c-3 -53 6 -75 16 -36 l6 22 15 -22 c9 -13 23 -23 31 -23
19 0 18 10 -2 25 -14 10 -14 15 -3 32 16 27 -2 53 -36 53 -21 0 -23 -6 -27
-51z m50 14 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16 0 18 31 15 38 -3z"/>
<path d="M3450 2115 c0 -30 5 -55 10 -55 6 0 10 25 10 55 0 30 -4 55 -10 55
-5 0 -10 -25 -10 -55z"/>
<path d="M3608 2126 c-4 -58 -1 -66 25 -66 36 0 45 13 44 60 -2 43 -4 45 -33
48 -31 3 -32 2 -36 -42z m52 14 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6
9 10 20 10 11 0 20 -4 20 -10z m0 -45 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20
15 0 8 9 15 20 15 11 0 20 -7 20 -15z"/>
<path d="M1820 1872 c0 -129 -6 -147 -55 -161 -22 -7 4 -9 90 -9 82 0 111 2
92 8 -50 15 -56 34 -57 163 l0 117 -35 0 -35 0 0 -118z"/>
<path d="M2406 1961 c64 -47 115 -102 135 -145 42 -92 12 -204 -69 -261 -40
-28 -50 -30 -131 -30 -77 0 -92 3 -124 25 -21 14 -50 43 -65 64 -24 35 -27 49
-27 115 1 59 6 86 23 119 12 23 20 42 17 42 -11 0 -56 -103 -61 -143 -26 -189
188 -322 381 -238 170 74 195 295 50 436 -45 43 -49 45 -107 45 l-61 0 39 -29z"/>
<path d="M2700 1981 c0 -17 92 -93 138 -112 103 -45 240 -46 326 -2 48 24 138
123 111 123 -2 0 -23 -20 -45 -44 -54 -59 -126 -89 -212 -89 -103 0 -154 19
-217 80 -34 33 -64 53 -78 53 -13 0 -23 -4 -23 -9z"/>
<path d="M990 1381 l0 -29 213 -6 c418 -12 2512 -34 2520 -26 5 4 7 18 5 31
l-3 24 -170 2 c-476 7 -2458 33 -2507 33 -57 0 -58 0 -58 -29z"/>
<path d="M994 1236 c-16 -42 -9 -43 288 -50 156 -3 695 -11 1198 -16 503 -5
988 -12 1078 -15 l162 -4 0 28 0 29 -122 6 c-68 3 -555 10 -1083 16 -528 5
-1085 13 -1237 16 -250 6 -278 5 -284 -10z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
